//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {
    CBtn: () => import("@/components/CBtn"),
    CPages: () => import("@/components/CPages"),
  },
  props: {
    //团队id
    teamId: {
      type: Number,
      default: null,
    },
    //当前用户角色
    UserMemberMType: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      loading: true,
      statusOptions: [
        {
          label: "所有",
          value: null,
        },
        {
          label: "已通过",
          value: 1,
        },
        {
          label: "未通过",
          value: -1,
        },
        {
          label: "未审核",
          value: 0,
        },
      ],
      numberScreen: null, //邀请人、被邀请人筛选
      timeScreen: null, //时间筛选
      statusScreen: null, //状态筛选
      tableData: [],
      pageData: {
        pageIndex: 1,
        pageSize: 10,
        totalNum: 0,
      },
    };
  },
  mounted() {
    this.getListData();
  },
  methods: {
    /**
     * 清空邀请
     */
    handleDel() {
      this.$confirm("此操作将清空所有记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const data = {
            Ids: "",
            teamId: this.teamId,
          };
          this.$http
            .post("/Teams/InvitedOrApply/DelSubmit.ashx", data)
            .then((resp) => {
              if (resp.res == 0) {
                this.$message({
                  type: "success",
                  message: "清空记录成功!",
                });
                this.handleSearch();
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    /**
     * 同意/拒绝申请
     */
    handleInv(val, type) {
      const data = {
        Ids: val ? [val.Id] : "",
        operation: type,
        teamId: this.teamId,
      };
      this.$http
        .post("/Teams/InvitedOrApply/BatchOperation.ashx", data)
        .then((resp) => {
          if (resp.res == 0) {
            this.$message({
              showClose: true,
              message: "申请处理成功！",
              type: "success",
            });
            this.getListData();
          }
        });
    },
    /**
     * 搜索
     */
    handleSearch() {
      this.pageData.pageIndex = 1;
      this.getListData();
    },
    /**
     * 邀请列表查询
     */
    getListData() {
      this.loading = true;
      const data = {
        name: this.numberScreen,
        teamId: this.teamId,
        pageIndex: this.pageData.pageIndex,
        pageSize: this.pageData.pageSize,
        status: this.statusScreen,
        sdate:
          this.timeScreen && this.timeScreen.length
            ? this.timeScreen[0].timeFormat("yyyy-MM-dd")
            : null,
        edate:
          this.timeScreen && this.timeScreen.length
            ? this.timeScreen[1].timeFormat("yyyy-MM-dd")
            : null,
      };
      this.$http
        .get("/Teams/InvitedOrApply/GetTeamApply.ashx", { params: data })
        .then((resp) => {
          if (resp.res == 0) {
            this.tableData = resp.data.data;
            this.pageData.totalNum = resp.data.total;
          }
        })
        .finally(() => (this.loading = false));
    },
    /**
     * 分页
     */
    handlePaginationChange(val) {
      this.pageData = val;
      this.getListData();
    },
  },
};
